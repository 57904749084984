<template>
  <div>
    <!-- User Delete Confirmation Popup   -->
    <ca-popup
      :header="'Delete User'"
      :toggle="openDeletePopup"
      @open="confirmDelete"
      @close="cancelDelete"
    >
      <template v-slot:content>
        Are you sure you want to delete the user?
      </template>
      <template v-slot:actions>
        <ca-button @click.native="deleteUser">Yes</ca-button>
      </template>
    </ca-popup>
    <!-- User Verification email confirmation popup   -->
    <ca-popup
      :header="'Send Verification Email'"
      :toggle="openEmailPopup"
      @open="confirmEmailSending"
      @close="cancelEmailSending"
    >
      <template v-slot:content>
        Are you sure you want to send verification email to user?
      </template>
      <template v-slot:actions>
        <ca-button @click.native="sendVerificationEmail">Yes</ca-button>
      </template>
    </ca-popup>
    <!-- User password reset confirmation popup   -->
    <ca-popup
      :header="'Reset User Password'"
      :toggle="openResetPasswordPopup"
      @open="confirmPasswordReset"
      @close="cancelPasswordReset"
    >
      <template v-slot:content>
        Are you sure you want to send this user a reset password link?
      </template>
      <template v-slot:actions>
        <ca-button @click.native="resetUserPassword">Yes</ca-button>
      </template>
    </ca-popup>
    <!-- User Verify confirmation popup   -->
    <ca-popup
      :header="'Verify User'"
      :toggle="openVerificationPopup"
      @open="confirmVerify"
      @close="cancelVerify"
    >
      <template v-slot:content>
        Are you sure you want to verify this user?
      </template>
      <template v-slot:actions>
        <ca-button @click.native="verifyUser">Yes</ca-button>
      </template>
    </ca-popup>

    <!-- User Upgrade confirmation popup   -->
    <ca-popup
      :header="'Upgrade User'"
      :toggle="openUpgradePopup"
      @open="confirmUpgrade"
      @close="cancelUpgrade"
    >
      <template v-slot:content>
        Are you sure you want to upgrade this user?
      </template>
      <template v-slot:actions>
        <ca-button @click.native="upgradeUser">Yes</ca-button>
      </template>
    </ca-popup>
    <page-header
      :title="'Users'"
      :sub-heading="true"
      :sub-header="'Dashboard / Users'"
      :link-name="'View Users'"
      :link-url="'/view-users'"
    >
      <ca-button
        :type="'ghost'"
        class="mr-1"
        @click.native="confirmVerify"
        :is-disabled="user.verified"
        >Verify</ca-button
      >
      <ca-button
        :type="'ghost'"
        class="mr-1"
        @click.native="confirmPasswordReset"
        >Reset Password</ca-button
      >
      <ca-button
        :type="'ghost'"
        class="mr-1"
        @click.native="confirmEmailSending"
        :is-disabled="user.verified"
        >Send Verification Email</ca-button
      >
      <ca-button :type="'ghost'" class="mr-1" @click.native="confirmUpgrade"
        >Upgrade</ca-button
      >
      <ca-button
        :type="'filled'"
        :color="'danger'"
        @click.native="confirmDelete"
        >Delete</ca-button
      >
    </page-header>

    <!-- Class name usp means - User Sub Page    -->
    <div class="usp sub-page-container row">
      <div class="col-xs-12">
        <div class="usp__nav tab-nav">
          <router-link
            :to="{ name: 'vu-edit-user', params: { id: $route.params.id } }"
            class="tab-nav__el"
            exact
            >Edit User
          </router-link>
          <router-link
            :to="{
              name: 'vu-payment-history',
              params: { id: $route.params.id },
            }"
            class="tab-nav__el"
            exact
            >Payment History
          </router-link>
          <router-link
            :to="{ name: 'vu-valuations', params: { id: $route.params.id } }"
            class="tab-nav__el"
            exact
            >Company & Valuations
          </router-link>
        </div>
        <div class="usp__content tab-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import CaButton from "../../components/buttons/CaButton";
import CaPopup from "../../components/popup/CaPopup";
import axios from "../../../axios";
export default {
  name: "ViewUser",
  data() {
    return {
      openUpgradePopup: false,
      openDeletePopup: false,
      openEmailPopup: false,
      openVerificationPopup: false,
      openResetPasswordPopup: false,
      user: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        verified: false,
      },
    };
  },
  methods: {
    confirmUpgrade() {
      this.openUpgradePopup = true;
    },
    cancelUpgrade() {
      this.openUpgradePopup = false;
    },
    upgradeUser() {
      this.$toast("User upgraded successfully", {
        type: "success",
        position: "top-center",
      });
      this.cancelUpgrade();
    },
    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete() {
      this.openDeletePopup = true;
    },
    deleteUser() {
      console.log(this.currentUser);
      // this.cancelDelete();
      const loading = this.$vs.loading();
      axios
        .patch(
          `${this.$store.state.server.requestUri}/admin/user/deactivate/${this.user.id}`
        )
        .then(() => {
          loading.close();
          this.$toast(
            `User with id ${this.currentUser.id} deleted successfully`,
            {
              position: "top-center",
              type: "success",
            }
          );
          this.cancelDelete();
          this.$router.push({ name: "users" });
        })
        .catch((err) => {
          loading.close();
          this.$toast(`An error occurred while deleting user`, {
            position: "top-center",
            type: "error",
          });
          this.cancelDelete();
          console.log(err.response);
        });
    },
    confirmPasswordReset() {
      this.openResetPasswordPopup = true;
    },
    cancelPasswordReset() {
      this.openResetPasswordPopup = false;
    },
    resetUserPassword() {
      const loading = this.$vs.loading();
      axios
        .post(`${this.$store.state.server.requestUri}/auth/request-otp/`, {
          email: this.user.email,
        })
        .then((res) => {
          this.cancelPasswordReset();
          this.$toast("User password reset link sent successfully", {
            position: "top-center",
            type: "success",
          });
          loading.close();
          this.setUserInfo(res.data.data);
        })
        .catch((err) => {
          this.cancelPasswordReset();
          loading.close();
          console.log(err);
        });
    },
    confirmEmailSending() {
      this.openEmailPopup = true;
    },
    cancelEmailSending() {
      this.openEmailPopup = false;
    },
    sendVerificationEmail() {
      const loading = this.$vs.loading();
      axios
        .post(
          `${this.$store.state.server.requestUri}/auth/resend-email-verification-otp`,
          {
            email: this.user.email,
          }
        )
        .then((res) => {
          this.cancelEmailSending();
          const response = res.data;
          loading.close();
          this.$toast(response.message, {
            position: "top-center",
            type: "success",
          });
        })
        .catch((err) => {
          this.cancelEmailSending();
          console.log(err.response);
          loading.close();
          this.$toast("Something went wrong", {
            position: "top-center",
            type: "error",
          });
        });
    },
    confirmVerify() {
      this.openVerificationPopup = true;
    },
    cancelVerify() {
      this.openVerificationPopup = false;
    },
    verifyUser() {
      const loading = this.$vs.loading();
      axios
        .patch(
          `${this.$store.state.server.requestUri}/admin/user/${this.user.id}/verify`
        )
        .then((res) => {
          this.cancelVerify();
          loading.close();
          this.$toast("User verified successfully", {
            position: "top-center",
            type: "success",
          });
          console.log(res);
          this.setUserInfo(res.data.data);
        })
        .catch((err) => {
          this.cancelVerify();
          loading.close();
          console.log(err);
        });
    },
    getUser() {
      axios
        .get(
          `${this.$store.state.server.requestUri}/admin/user/${this.$route.params.id}`
        )
        .then((res) => {
          const data = res.data.data.user;
          this.setUserInfo(data);
        })
        .catch((err) => console.log(err));
    },
    setUserInfo(data) {
      console.log(data);
      this.user.id = data.id;
      this.user.first_name = data.first_name;
      this.user.last_name = data.last_name;
      this.user.email = data.email;
      this.user.verified =
        data.email_verified_at !== null && data.email_verified_at !== "";
    },
  },
  components: { CaPopup, CaButton, PageHeader },
  created() {
    this.getUser();
  },
};
</script>

<style scoped>
/*.usp {*/
/*  width: 100%;*/
/*  padding: 2rem;*/
/*  background-color: #ffffff;*/
/*}*/

/*.usp__content {*/
/*  padding: 4rem 2rem 2rem;*/
/*}*/
</style>
